.leader-top {
  margin-top: 80px;
}
.bor-top {
  width: 100%;
  height: 1px;
  background-color: #000000;
}
.lead-name {
  font-family: "Poppins";
  font-size: 2.5vw;
  font-weight: 600;
  line-height: 54px;
  text-align: center;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
}
.text-underline {
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.text-underline::after {
  width: 110%;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3px;
  height: 5px;
  background: linear-gradient(
    to right,
    #50a1ff,
    #3749a6,
    #4c61cc,
    #565ea1,
    #4262ff
  );
}
.leaderdiv {
  padding: 1% 10% 0;
}
.leaderdiv1 {
  padding: 1% 8%;
}
.leadergrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.ceoimg {
  width: 70%;
  height: 27rem;
  margin-left: 20%;
  border-radius: 120px 40px 40px 40px;
  object-fit: cover;
  z-index: 10;
  position: relative;
}
.quotepadd {
  padding: 15% 0 0 10%;
}
.qoutetext {
  font-size: 2.4vw;
  font-weight: 500;
  color: #173a4b;
  line-height: 1.5;
  margin-left: 10px;
}
.leaderarrow {
  left: -25px;
  width: 45px;
  height: 30px;
  position: relative;
}
.lettercircle {
  width: 255px;
  height: 255px;
  position: absolute;
  top: -80px;
  z-index: 0;
  left: 0px;
}
.ceocircle1 {
  background: linear-gradient(
    180deg,
    rgb(91, 138, 178) 0%,
    rgb(221, 225, 238, 0) 100%
  );
  width: 90px;
  height: 90px;
  rotate: -30deg;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: -30px;
}
.ceocircle2 {
  background: linear-gradient(
    180deg,
    rgb(103, 142, 176) 0%,
    rgb(221, 225, 238, 0) 100%
  );
  width: 80px;
  height: 80px;
  rotate: -30deg;
  border-radius: 50%;
  position: absolute;
  right: -13px;
  bottom: 120px;
  z-index: -1;
}
.ceocircle3 {
  background: linear-gradient(
    180deg,
    rgb(118, 163, 201) 0%,
    rgb(221, 225, 238, 0) 100%
  );
  width: 80px;
  height: 80px;
  rotate: -30deg;
  border-radius: 50%;
  position: absolute;
  left: 5%;
  bottom: 50px;
  z-index: -1;
}
.lastname {
  font-size: 1.8vw;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #0b090a;
}
.lastdesignation {
  font-size: 1.3vw;
  line-height: 1;
  text-align: center;
  color: #173a4b;
}
.leader-bio{
    font-size: 1vw;
    text-align: center;
}
@media (max-width: 992px) {
  .leaderdiv {
    padding: 1% 3%;
  }
  .ceoimg {
    height: 20rem;
  }
  .text-underline::after {
    bottom: 0px;
  }
  .ceocircle2 {
    right: 0px;
  }
}
@media (max-width: 767px) {
  .leadergrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .ceoimg {
    height: 24rem;
  }
  .leaderdiv {
    padding: 10% 3%;
  }
  .lastname {
    font-size: 3.8vw;
  }
  .lastdesignation {
    font-size: 3.3vw;
  }
  .lead-name {
    font-size: 6.5vw;
  }
  .qoutetext {
    font-size: 6.4vw;
  }
  .ceocircle2 {
    right: 10px;
  }
  .leader-bio {
    font-size: 4vw;
    text-align: center;
}
}
