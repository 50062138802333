.homeabout_div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.width50 {
  width: 37%;
}

.aboutfirstimg {
  width: 24rem;
  height: 24rem;
  border-radius: 50%;
  margin-top: 5%;
  object-fit: cover;
}

.about-content-div {
  width: 100%;
  padding-left: 15%;
  padding-top: 5%;
}

.paddright {
  padding-right: 40%;
}

.nameheading {
  font-family: "Poppins", sans-serif;
  font-size: 1.7vw;
  font-weight: 600;
  line-height: 1;
  text-align: left;
}

.designationtext {
  font-family: "Poppins", sans-serif;
  font-size: 1.1vw;
  font-weight: 500;
  text-align: left;
  color: #173a4b;
}

.main-button {
  background-color: #173a4b;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  color: white;
}

.leftimg {
  width: 87%;
  height: 18rem;
  position: relative;
  left: 0;
  z-index: 1;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 4px 10px 30px 0px #00000026;
}

.rightimg {
  width: 87%;
  height: 18rem;
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  box-shadow: 4px 10px 30px 0px #00000026;
}

.rightimgdiv {
  margin-bottom: 40px;
}

.gradientcircle1 {
  width: 55px;
  height: 55px;
  position: absolute;
  bottom: 45%;
  right: 5%;
}

.gradientcircle2 {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 50%;
  bottom: 10%;
}

.gradientcircle3 {
  width: 55px;
  height: 55px;
  position: absolute;
  left: 6%;
  top: 8%;
}

.gradientcircle4 {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 0%;
  top: 15%;
  background: linear-gradient(100deg,
      rgb(149, 165, 177) 0%,
      rgb(205, 213, 233) 100%);
  border-radius: 50%;
}

.starimg {
  width: 32px;
  height: 32px;
}

.lettercircle {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: 10%;
  left: -20px;
}

.missionmaindiv {
  background-color: #173a4b;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.missioninnderdiv {
  color: white;
  padding: 50px 30px;
}

.missioninnderdiv:hover {
  background-color: white;
  color: #173a4b;
}

.mission-heading {
  font-family: "Poppins";
  font-size: 1.2vw;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.15em;
  text-align: center;
}
.mission-icon {
  width: 25px;
  height: 25px;
}
.about-leader-img {
  width: 14rem;
  height: 14rem;
  object-fit: cover;
  border-radius: 50%;
}
.leader-name {
  font-family: "Poppins", sans-serif;
  font-size: 1.4vw;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
}
.leader-designation {
  font-family: "Poppins", sans-serif;
  font-size: 1.1vw;
  line-height: 17.5px;
  text-align: center;
}
.leadingdiv {
  background-image: url("../../../public/leadingimage.webp");
  background-size: 100% 100%;
  height: 14.7vw;
  position: relative;
  background-repeat: no-repeat;
}
.leadingdiv::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #6c6b6b6f !important;
}
.leadinghead {
  font-family: "Poppins", sans-serif;
  font-size: 2.4vw;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  color: white;
  position: absolute;
  top: 35%;
  left: 49.2%;
  transform: translate(-50%, -50%);
  width: 30%;
}
.aboutwhiteline {
  width: 54%;
  background-color: white;
  height: 1px;
  margin-left: 24%;
  margin-top: 8px;
}
.leadingtext {
  font-family: "Poppins";
  font-size: 1.1vw;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  color: white;
  position: absolute;
  top: 61%;
  left: 22.5%;
}
.abtbutton {
  padding: 11px 25px;
  border-radius: 30px;
  background-color: #173a4b;
  color: white;
  text-align: center;
  font-size: 1.2vw;
  font-weight: 400;
  outline: none;
  margin-bottom: 20px;
  border: none;
}
.abtbutton:hover {
  background-color: white;
  outline: 2px solid #173a4b;
  color: #173a4b;
}
.abtpadding {
  padding: 0 2%;
}
.aboutbackground-video {
  width: 94%;
  height: 28rem;
  margin-left: 3%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 30px;
  margin-top: 80px;
}
.abouthero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.aboutbackground-video::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #173a4b57 !important;
  border-radius: 30px;
}
.abtthreegrid {
  display: grid;
  grid-template-columns: .8fr 1.4fr .8fr;
  width: 100%;
  overflow: hidden;
}
.centerend{
  display: flex;
  justify-content: end;
}
@media (min-width: 1340px) {
  .aboutbackground-video {
    height: 30rem;
  }
  .gradientcircle4 {
    top: 20%;
  }
  .about-leader-img {
    width: 15rem;
    height: 15rem;
}
}
@media (max-width: 992px) {
  .aboutfirstimg {
    width: 19rem;
    height: 19rem;
  }
  .about-content-div {
    width: 95%;
    padding-left: 12%;
  }
  .leftimg {
    height: 12rem;
  }
  .aboutbackground-video {
    height: 17rem;
  }
  .rightimg {
    height: 12rem;
  }
  .mission-heading {
    font-size: 2.5vw;
  }
  .leadingtext {
    line-height: 2;
  }
    .about-leader-img {
      width: 13rem;
      height: 13rem;
  }
  .rightimgdiv {
    margin-bottom: 20px;
  }
  .gradientcircle4 {
    top: 30%;
  }
  .leader-designation {
    font-size: 2.3vw;
  }
  .leader-name {
    font-size: 2.8vw;
  }
  .missioninnderdiv {
    padding: 20px 15px;
  }
}
@media (max-width: 767px) {
  .homeabout_div {
    display: grid;
    grid-template-columns: 1fr;
  }
  .aboutbackground-video {
    height: 14rem;
  }
  .aboutfirstimg {
    width: 18rem;
    height: 18rem;
    margin-top: 50px;
  }
  .about-content-div {
    width: 100%;
    padding: 5%;
  }
  .nameheading {
    font-size: 4.5vw;
    line-height: 1;
  }
  .designationtext {
    font-size: 4vw;
  }
  .rightimg {
    position: relative;
    height: 16rem;
    left: 100px;
    margin-top: 10px;
  }
  .abtthreegrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .leftimg {
    height: 16rem;
  }
  .about-leader-img {
    height: 19rem;
    width: 19rem;
  }
  .centerend{
    justify-content: center;
  }
  .abtbutton {
    padding: 3%;
    font-size: 4vw;
    width: 200px;
  }
  .gradientcircle1 {
    bottom: 20%;
  }
  .starimg {
    margin-top: 20px;
  }
  .gradientcircle3 {
    width: 70px;
    height: 70px;
  }
  .lettercircle {
    top: 0%;
    left: 20px;
  }
  .missionmaindiv {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 30px;
  }
  .mission-heading {
    font-size: 6vw;
  }
  .missioninnderdiv {
    padding: 35px 35px;
    height: 31rem;
  }
  .leadingtext {
    font-size: 3vw;
    left: 0;
    line-height: 1.3;
  }
  .leader-name {
    font-size: 5vw;
  }
  .leader-designation {
    font-size: 4vw;
  }
  .abtpadding {
    padding: 0 5%;
  }
  .leadingdiv {
    height: 25vh;
  }
  .gradientcircle4 {
    top: -10%;
  }
  .paddright {
    padding-right: 0;
  }
  .leadinghead {
    font-family: "Poppins", sans-serif;
    font-size: 5vw;
    left: 56.2%;
    transform: translate(-60%, -50%);
    width: 60%;
  }
}
@media (max-width: 376px) {
  .missioninnderdiv {
    height: 30rem;
  }
}
@media (max-width:350px) {
  .about-leader-img {
    height: 18rem;
    width: 18rem;
}
}