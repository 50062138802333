
.container_backdiv1 {
  padding: 3% 5%;
}

.blogpagetop {
  margin-top: 70px;
}

.hero_heading_blog {
  font-size: 4vw;
  font-family: Poppins;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  position: absolute;
  top: 36%;
  left: 5%;
  color: white;
}

.blog_headingsec {
  width: 100%;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  margin-bottom: 3%;
}

.blog_text1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.blog_head {
  font-size: 16.1vw;
  letter-spacing: 1vw;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  color: #1a1a1a;
}

.recent_post_heading {
  font-size: 1.7vw;
  font-weight: 550;
  line-height: 1.5;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1a1a1a;
}

.blog_recentdiv1 {
  margin-top: 2%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.recent_imgdiv {
  width: 100%;
  height: 260px;
}

.recent_imgdiv2 {
  width: 100%;
  height: 270px;
}

.recent_imgs {
  width: 100%;
  height: 100%;
  object-fit: cover contain;
}

.purple_txt {
  font-size: 1vw;
  font-weight: 520;
  line-height: 1.3;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6941c6;
}

.recent_contentdiv {
  margin-top: 3%;
}

.recent_section {
  display: grid;
  grid-template-columns: 1.1fr 0.9fr;
  grid-gap: 20px;
  margin-bottom: 5%;
}

.recent_section1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 5%;
}

.recent_imgdiv1 {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.recent_heading1 {
  font-size: 1.6vw;
  font-weight: 550;
  line-height: 1.3;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1a1a1a;
}

.blog_arrowicon {
  font-size: 30px;
  color: #1a1a1a;
  rotate: 35deg;
}

.research_purple {
  background-color: #eef4ff;
  padding: 2px 10px;
  border-radius: 16px;
  border: none;
  font-size: 0.9vw;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3538cd;
}

.research_red {
  background-color: #fdf2fa;
  padding: 2px 10px;
  border-radius: 16px;
  border: none;
  font-size: 0.9vw;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #c11574;
}

.blog_head11 {
  font-size: 1.1vw;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1a1a1a;
}

.blog_grid_section {
  margin-top: 3%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.blogcard_head {
  font-size: 1.5vw;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1a1a1a;
}

.blogcardimg {
  width: 100%;
  height: 210px;
}

.blogpage_grid {
  display: grid;
  grid-template-columns: 70% auto;
  grid-gap: 30px;
}

.blogpage_imgdiv1 {
  width: 100%;
  height: 333px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page_button {
  font-family: "poppins";
  padding: 10px 18px;
  margin: 0 5px;
  font-size: 0.9vw;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  border-radius: 10px;
}

.page_button.active {
  background-color: #f9f5ff;
  color: #7f56d9;
}

.page_button:hover {
  background-color: #7f56d9;
  color: white;
}

.next_button {
  border: none;
  font-size: 1vw;
  background-color: white;
  color: #667085;
}

.blogpage_heading {
  font-size: 2vw;
  font-weight: 700;
  line-height: 1.3;
  text-align: left;
  color: #181D24;
}

.blogpage_subheading {
  font-size: 1.7vw;
  font-weight: 500;
  line-height: 1.3;
  text-align: left;
  color: #181D24;
}

.ekakshar_text {
  margin-top: 20px;
  font-size: 1vw;
  font-weight: 500;
  line-height: 1.1;
  text-align: left;
  color: #181D24;
}

.blogpage_date {
  font-size: 0.9vw;
  font-weight: 500;
  line-height: 0.1;
  text-align: left;
  color: #B3B3B3;
}

.blogpage_heading2 {
  font-size: 1.5vw;
  font-weight: 700;
  line-height: 1;
  text-align: left;
  color: #173A4B;
}

.recent_blogcard {
  margin-top: 14%;
}

.recent_blogimg {
  width: 100%;
  height: 180px;
  /* object-fit: contain cover; */
}

.recent_contentdiv1 {
  margin-top: 5%;
}

.blogpage_arrow {
  font-size: 20px;
  color: #1A1A1A;
  rotate: 35deg;
}

.recentblog_heading {
  margin-top: 3%;
  font-size: 2vw;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: #173A4B;
}

.recent_blog_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px
}

.blog_view_btn {
  margin-top: 3%;
  font-size: 14px;
  font-weight: 500;
  line-height: 41px;
  text-align: center;
  color: #181D24;
  text-decoration: underline;
  cursor: pointer;
}

.blog_back {
  font-size: 1.1vw;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  color: #181D24;
  text-decoration: underline;
}

.sticky_div {
  position: sticky;
  top: 0;
}

.blog_bold {
  color: #3538CD;
}

.blogbackground-video {
  width: 94%;
  height: 28rem;
  margin-left: 3%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 30px;
  margin-top: 80px;
}

.bloghero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.blogbackground-video::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #173a4b57 !important;
  border-radius: 30px;
}
@media (min-width:1340px) {
  .vlogbackground-video{
    height: 30rem;
  }
  }
@media (max-width: 992px) {
  .recent_imgdiv1 {
    height: 130px;
  }

  .recent_section1 {
    margin-bottom: 0%;
  }

  .recent_imgdiv2 {
    height: auto;
  }
  .blogbackground-video{
    height: 17rem;
  }
  .recent_imgdiv {
    height: auto;
  }
}
@media (max-width: 767px) {
  .blogpage_grid {
    grid-template-columns: 100%;
    grid-gap: 50px
  }
  .blogpage_heading {
    font-size: 4vw;  
  }
  .blogbackground-video{
    height: 14rem;
  }
  .ekakshar_text {
    margin-top: 20px;
    font-size: 2.5vw;
  }
  .blogpage_date {
    font-size: 2.3vw;
  }
  .blogpage_imgdiv1 {
    width: 100%;
    height: 200px;
  }
  .blogpage_heading2 {
    font-size: 3vw;
  }
  .recent_blogcard {
    margin-top: 8%;
  }
  .blog_head11 {
    font-size: 3.2vw;
  }
  .recentblog_heading {
    margin-top: 8%;
    font-size: 5vw;
  }
  .recent_blog_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px
  }
  .blog_back {
    font-size: 3vw;
    font-weight: 500;
    line-height: 1;
    text-align: left;
    color: #181D24;
    text-decoration: underline;
  }
  .blogpage_subheading {
    font-size: 4.6vw;
  }
  .sticky_div {
    display: none;
  }
  .blog_recentdiv1 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
  .recent_section {
    display: none;
  }
  .recent_section1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .blog_grid_section {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  .recent_post_heading {
    font-size: 4vw;
  }
  .purple_txt {
    font-size: 3vw;
  }
  .blogcard_head {
    font-size: 3.5vw;
    font-weight: 600;
  }
  .recent_heading1 {
    font-size: 4vw;
  }
  .research_purple {
    font-size: 2.9vw;
  }
  .research_red {
    font-size: 2.9vw;
  }
  .next_button {
    font-size: 3vw;
  }
  .page_button {
    font-size: 3vw;
  }
}