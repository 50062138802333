@import url('https://fonts.googleapis.com/css?family=Fira+Sans');


/* Mixins */
@mixin smallscreens {
  @media (max-width: 770px) {
    @content;
  }
}




.body1 {
  color: #f5f6fa;
}

.background1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#0C0E10, #446182);

  .ground {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25vh;
    background: #0C0E10;

    @include smallscreens {
      height: 0vh;
    }
  }
}

.container1 {
  position: relative;
  margin: 0 auto;
  width: 85%;
  height: 100vh;
  padding-bottom: 25vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @include smallscreens {
    flex-direction: column;
    padding-bottom: 0vh;
  }
}

.left-section, .right-section {
  position: relative;
}

/* Left Section Styles */
.left-section {
  width: 40%;

  @include smallscreens {
    width: 100%;
    height: 40%;
    position: absolute;
    top: 0;
  }

  .inner-content {
    @extend %Ycenter;

    @include smallscreens {
      position: relative;
      padding: 1rem 0;
    }
  }

  .heading {
    text-align: center;
    font-size: 9em;
    line-height: 1.3em;
    margin: 2rem 0 0.5rem 0;
    padding: 0;
    text-shadow: 0 0 1rem #fefefe;
    color: white;
  }

  .subheading {
    text-align: center;
    max-width: 480px;
    font-size: 1.5em;
    line-height: 1.15em;
    padding: 0 1rem;
    margin: 0 auto;
    color: white;
  }
}

.right-section {
  width: 50%;

  @include smallscreens {
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
  }

  .svgimg {
    position: absolute;
    bottom: 0;
    padding-top: 10vh;
    padding-left: 1vh;
    max-width: 100%;
    max-height: 100%;

    @include smallscreens {
      padding: 0;
    }

    .bench-legs {
      fill: #0C0E10;
    }

    .top-bench, .bottom-bench {
      stroke: #0C0E10;
      stroke-width: 1px;
      fill: #5B3E2B;
    }

    .bottom-bench path:nth-child(1) {
      fill: darken(#5B3E2B, 7%);
    }

    .lamp-details {
      fill: #202425;
    }

    .lamp-accent {
      fill: lighten(#202425, 5%);
    }

    .lamp-bottom {
      fill: linear-gradient(#202425, #0C0E10);
    }

    .lamp-light {
      fill: #EFEFEF;
    }
  }
}

/* Keyframes */
@keyframes glow {
  0% {
    text-shadow: 0 0 1rem #fefefe;
  }
  50% {
    text-shadow: 0 0 1.85rem #ededed;
  }
  100% {
    text-shadow: 0 0 1rem #fefefe;
  }
}
