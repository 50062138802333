.teampadd {
    padding: 1% 3.5%;
}
.Teams-hero-text {
    font-size: 4vw;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 1.2;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 25%;
    color: white;
    transform: translate(-50%, -50%);
}
.team-name1 {
    font-family: "Poppins";
    font-size: 1.2vw;
    font-weight: 600;
    line-height: 1;
    text-align: center;
}
.teamcard {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}
.Team-card-img {
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    object-fit: cover;
}
.Team-card-img1 {
    width: 14.5rem;
    height:  14.5rem;
    border-radius: 50%;
    object-fit: cover;
}
.teamiconback {
    font-size: 2vw;
    border-radius: 50%;
    color: black;
    font-size: 20px;
    text-align: center;
}
.icongrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 10% 10px;
}
.teamgrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}
.teambackground-video {
    width: 94%;
    height: 28rem;
    margin-left: 3%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 30px;
    margin-top: 80px;
  }
  .teamhero-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
  }
  .teambackground-video::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #173a4b57 !important;
    border-radius: 30px;
  }
  @media (min-width:1340px) {
    .teambackground-video {
      height: 30rem;
    }
  }
@media (max-width:1100px) {
    .Team-card-img1 {
        width: 12.5rem;
        height: 12.5rem;
    }
    .Team-card-img {
        width: 18rem;
        height: 18rem;
    }
}
@media (max-width:992px) {
    .teamgrid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }
    .team-name1 {
        font-size: 2.1vw;
    }
    .teambackground-video {
        height: 17rem;
      }
}
@media (max-width:767px) {
    .Teams-hero-text {
        font-size: 7vw;
        top: 50%;
        left: 35%;
    }
    .Team-card-img {
        width: 20.5rem;
        height: 20.5rem;
    }
    .team-name1 {
        font-size: 4.2vw;
    }
    .Team-card-img1 {
        width: 20.5rem;
        height: 20.5rem;
    }
    .teambackground-video {
        height: 14rem !important;
      }
}
@media (max-width:350px) {
    .Team-card-img1 {
        width: 18.5rem;
        height: 18.5rem;
    }
}