.footerdiv{
    background-color: #E8EBED;
    height: auto;
    padding: 3.3% 9%;
}
.footerdiv2{
    background-color: #0B090A;
    height: auto;
    padding: 15px 9% 0;
}
.footercontainer{
    display: grid;
    grid-template-columns: 1.2fr .6fr .9fr 1.3fr;
}
.footercontainer2{
    display: grid;
    grid-template-columns: 70% 30%;
}
.footerimg{
width: 50%;
height: 80px;
object-fit: contain;
margin-left: 10%;
}
.footerinput{
    background-color: #FFFFFF;
    border-radius: 30px;
    border: none;
    height: 50px;
    width: 100%;
    padding-left: 20px;
}
.footerbutton{
    background-color: #173A4B;
    border-radius: 50%;
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 2%;
    top: 5px;
    padding: 5px;
}
.footerinput:focus{
    outline: none;
}
.footerlist{
    list-style: none;
    text-align: left;
    font-size: 1.1vw;
    line-height: 2;
    font-weight: 400;
    color: #2B2B2B;
}
.footericon{
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
.followicon{
    color: #173A4B;
    width: 22px;
    height: 22px;
    margin-right: 13px;
    margin-top: 12px;
}
.footertext{
    font-size: 1.1vw;
    line-height: 1.8;
    font-weight: 500;
    color: #2B2B2B;
    padding-right: 30%;
}
.footerhead{
    font-size: 1.3vw;
    font-weight: 500;
}
.buttonright{
    font-size: 18px;
}
.reservedtext{
    color: #AAAAAA;
    font-size: 1vw;
    line-height: 10px;
}

.listmargin{
    margin-top: 40px;
}
@media (max-width:992px) {
    .footercontainer{
        grid-template-columns: repeat(2, 1fr) ;
        grid-gap: 20px;
    }
    .footertext{
        font-size: 1.6vw;
    }
    .footerlist{
        font-size: 1.6vw;
    }
    .footerhead{
        font-size: 2vw;
    }

}
@media (max-width:767px) {
    .footercontainer{
        grid-template-columns: repeat(1, 1fr) ;
    }
    .lapview{
        display: none;
    }
    .footertext{
        text-align: center;
        color: #808080;
        padding-right: 0;
    }
    .center1{
        display: flex;
        justify-content: center;
    }
}
@media (max-width:480px) {
    .footerdiv{

        padding: 20px;
    }
    .footertext{
        font-size: 4vw;
    }
    .footerlist{
        font-size: 4vw;
    }
    .footerhead{
        font-size: 5vw;
    }
    .footercontainer2{
        grid-template-columns: repeat(1, 1fr) ;
    }
    .reservedtext {
        line-height: 15px;
        font-size: 3vw;
    }
    .footerimg{
        width: 60%;
        height: 100px;
        margin-left: 20%;
    }
}