.contactherosec {
  background-image: url("../../../public/Contact-us.webp");
}
.hero_heading_white1 {
  font-size: 3.8vw;
  font-family: Poppins;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 19%;
  color: white;
  transform: translate(-50%, -50%);
}
.contactcontainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.contacticon {
  width: 45px;
  height: 45px;
}
.rightborder {
  border-right: 1px solid #173a4b;
}
.contact-subhead {
  font-size: 2vw;
  width: 50%;
  color: #173a4b;
  text-align: center;
}
.contactcircle1 {
  width: 48.93px;
  height: 48.47px;
  background: linear-gradient(180deg, #163848 0%, rgba(67, 97, 238, 0) 100%);
  position: absolute;
  top: -15%;
  left: 40%;
  border-radius: 50%;
  opacity: 0.5;
}
.inputpadding{
  padding: 1% 5%;
}
.registerbutton{
  background-color: #173A4B;
  color: white;
  border-radius: 40px;
  border: none;
  width: 100%;
  padding: 3%;
}
.contactcircle2 {
  width: 60.93px;
  height: 60.47px;
  background: linear-gradient(180deg, rgb(166,179,196) 0%, rgba(67, 97, 238, 0) 100%);
  position: absolute;
  bottom: 10%;
  left: -30px;
  border-radius: 50%;
  opacity: 0.5;
}
.contactcircle3 {
    width: 100.93px;
    height: 100.47px;
    background: linear-gradient(180deg, #163848 0%, rgba(67, 97, 238, 0) 100%);
    position: absolute;
    right: 10%;
    top: -5%;
    border-radius: 50%;
    opacity: 0.5;
}
.contactcircle4 {
    width: 90.93px;
    height: 90.47px;
    background: linear-gradient(180deg, #163848 0%, rgba(67, 97, 238, 0) 100%);
    position: absolute;
    bottom: -5%;
    left: 20%;
    border-radius: 50%;
    opacity: 0.5;
}
.contactcircle5 {
  width: 100.93px;
  height: 100.47px;
  background: linear-gradient(180deg, #163848 0%, rgba(67, 97, 238, 0) 100%);
  position: absolute;
  bottom: 30%;
  left: 10%;
  border-radius: 50%;
  opacity: 0.5;
}
.formdiv {
  width: 78%;
  background-color: White;
  box-shadow: 10px 30px 50px 0px #0000000d;
  border-radius: 30px;
  padding: 30px;
  z-index: 1;
}
.getindiv {
  border-bottom: 3px solid #173a4b;
}
.contactinput {
  background-color: #f6f6f6;
  width: 100%;
  height: 70.23px;
  border: none;
  border-radius: 20px;
  margin-top: 20px;
  padding-left: 20px;
  color: #b3b3b3;
}
.contactinput::-webkit-outer-spin-button,
.contactinput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.contactinput[type=number] {
  -moz-appearance: textfield;
}
.contactinput::placeholder{
font-size: 1vw;
font-weight: 400;
line-height: 26px;
text-align: left;
color: #AAAAAA;
}
.contactinput:focus {
  outline: none;
}
.getintext{
font-family: Poppins;
font-size: 1.2vw;
font-weight: 500;
line-height: 29.1px;
text-align: center;
}
.followicon1{
    width: 60.17px !important;
height: 60px;
color: #173A4B;
margin-right: 5px;
margin-left: 5px;
}
.contactpadd{
  padding: 30px;
  
}
@media (max-width:992px) {
    .getintext {
        font-size: 2.2vw;
    }
    .contact-subhead {
        font-size: 4vw;
        width: 70%;
    }
    
    
}
@media (max-width:767px) {
    .contactcontainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .formdiv {
        width: 90%;
    }
    .getintext {
        font-size: 3.2vw;
    }
    .contact-subhead {
        font-size: 5vw;
        width: 90%;
    }
    .rightborder {
      border-right: none;
    }
    .hero_heading_white1{
      font-size: 7vw;
      left: 30%;
    }
    .contactcircle1 {
      top: -35%;
      left: 26%;
  }
  .contactinput::placeholder{
    font-size: 4vw;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #AAAAAA;
    }
}