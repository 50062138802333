.homehero_maindivs {
  width: 100%;
  height: 100%;
  padding-top: 70px;
}
.home_herodiv1{
  padding-top: 3%;
}
.home_herodiv {
  width: 100%;
  height: 125%;
  background: linear-gradient( 180deg, white, rgb(161, 198, 215) 100%  );
  opacity: .8;
  border-radius: 0 0 800px 800px;
  position: absolute;
  z-index: -1;
}
.homehero_grid {
  display: grid;
  grid-template-columns: 63% auto;
  grid-gap: 0px;
}
.hero_blue_circle {
  width: 60px;
  height: 60px;
  background: linear-gradient(180deg, #173a4b 0%, rgba(67, 97, 238, 0) 100%);
  border-radius: 50%;
  position: absolute;
  top: -12%;
  right: -3%;
}
.swip_arrow_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hero_swip {
  width: 100%;
  height: 19rem;
  border-radius: 20px;
  overflow: visible;
}
.home_hero_txtdiv {
  width: 75%;
}
.swip_content {
  width: 82%;
  position: relative;
  border-radius: 20px;
  overflow: visible;
}
.hero_img {
  width: 97%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 3px 4px 10px 0px #00000033;
  position: absolute;
}
.swip_circle {
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  box-shadow: 4px 10px 30px 0px #0000001a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  cursor: pointer;
  z-index: 10;
}
.swip_circle_left {
  left: -10px;
}
.swip_circle_right {
  right: -10px;
}
.hero_arrow_icon {
  font-size: 20px !important;
  color: #aaaaaa;
}
.heroform_maindiv {
  width: 100%;
  height: auto;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 10px 30px 50px 0px #0000000d;
  position: relative;
  z-index: 10;
}
.form_headdiv {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid #173a4b;
}
.form_heading {
  font-size: 1vw;
  font-weight: 550;
  line-height: 1.2;
  text-align: center;
  color: #173a4b;
}
.form_div {
  width: 100%;
  padding: 40px 30px 40px 30px;
}
.infield {
  width: 100%;
  height: 3.5rem;
  border-radius: 20px;
  padding-left: 20px;
  border: none;
  background-color: #d4d4d433;
  margin-bottom: 25px;
}
.infield::-webkit-outer-spin-button,
.infield::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.infield[type=number] {
  -moz-appearance: textfield;
}
.infield::placeholder {
  font-size: 14px;
  color: #aaaaaa;
}
.registerinfield {
  width: 100%;
  height: 3.5rem;
  border-radius: 20px;
  padding-left: 20px;
  border: none;
  background-color: #d4d4d433;
}
.registerinfield::placeholder {
  font-size: 14px;
  color: #aaaaaa;
}
.regbtn {
  width: 100%;
  height: 50px;
  border: 1px solid #bfbdc1;
  background-color: #173a4b;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.custom-pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.custom-pagination .swiper-pagination-bullet {
  width: 100%;
  height: 4px;
  margin: 0 10px !important;
  background-color: #FFFFFF;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.custom-pagination .swiper-pagination-bullet-active {
  background: #173a4b;
  opacity: 100%;
}
.about_maindiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.homeabt_imgdiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
.homeabt1 {
  margin-top: 20%;
  width: 100%;
  height: 29rem;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 4px 10px 30px 0px #00000026;
}
.abtimg1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeabt2 {
  width: 100%;
  height: 260px;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 4px 10px 30px 0px #00000026;
  position: relative;
  z-index: 10;
}
.homeabt3 {
  margin-top: 20px;
  width: 100%;
  height: 160px;
  border-radius: 30px;
  box-shadow: 4px 10px 30px 0px #00000026;
  overflow: hidden;
}
.homeabt_data {
  width: 70%;
  height: auto;
  box-shadow: 4px 10px 30px 0px #0000000f;
  background-color: #ffffff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 10px 30px 5px 30px;
  margin-bottom: 30px;
}
.userdata_icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.abtdata_head {
  font-size: 1.5vw;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  color: #173a4b;
}
.cicle_text {
  height: 200px;
  width: 200px;
  position: absolute;
  top: -10%;
  left: 30%;
}
.cicle_imgtxt {
  width: 100%;
  height: 100%;
}
.blue_circle1, 
.blue_circle2, 
.blue_circle {
  border-radius: 50%;
  opacity: 50%;
  position: absolute;
}
.blue_circle1, 
.blue_circle2 {
  width: 60px;
  height: 60px;
  background: linear-gradient(180deg, #173A4B 0%, rgba(67, 97, 238, 0) 100%);
}
.blue_circle1 {
  top: 3%;
  left: 95%;
}
.blue_circle2 {
  bottom: -25%;
  left: 30%;
}
.blue_circle {
  width: 90px;
  height: 90px;
  background: linear-gradient(180deg, #638cbb 0%, #e4e7f181 100%);
  top: -10%;
  left: 5%;
  z-index: -1;
  transform: rotate(-45deg);
}
.home_mobdata {
  display: none;
}
.buy_button {
  width: auto;
  height: auto;
  padding: 15px 30px 15px 30px;
  background-color: #173a4b;
  font-size: 1vw;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
  border: none;
  border-radius: 30px;
}
.exclusive_swiper_div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.exclusive_swipediv {
  padding-left: 10%;
}
.exclusive_carddiv {
  width: 100%;
  height: auto;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.exe_img {
  width: 100%;
  height: 22rem;
  background: linear-gradient(0deg, #d9d9d9, #d9d9d9),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  object-fit: cover;
  border-radius: 30px;
}
.exe_rqst_div {
  height: 41px;
  padding: 0px 20px 0px 20px;
  background-color: transparent;
  position: absolute;
  bottom: 5%;
  left: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.rqst_text {
  margin-top: 15px;
  font-size: 1.1vw;
  font-weight: 500;
  line-height: 2;
  text-align: center;
}
.exe_type {
  margin-top: 3%;
  font-size: 1.8vw;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  color: #2b2b2b;
}
.exe_name {
  font-size: 1.4vw;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2b2b2b;
}
.why_maindiv {
  margin-top: 3%;
  width: 100%;
  height: auto;
  background-color: #f2f2f2;
  padding-left: 11%;
  padding-right: 11%;
  padding-top: 8%;
  padding-bottom: 8%;
}
.whygrid_div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  margin-top: 5%;
}
.container_lastdiv {
  padding: 3% 8%;
}
.whyek_card {
  width: 100%;
  height: 20rem;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 30px;
  box-shadow: 4px 10px 30px 0px #00000008;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.whyek_card:hover {
  transform: translateY(-30px);
}
.why_circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #173a4b;
  box-shadow: 4px 10px 30px 0px #4361ee66;
  display: flex;
  align-items: center;
  justify-content: center;
}
.why_text_div {
  padding: 0px 20px 0px 20px;
}
.handshake_icon {
  font-size: 40px;
  color: #ffffff;
}
.why_heading {
  margin-top: 8%;
  font-size: 1.4vw;
  font-weight: 500;
  line-height: 2;
  text-align: center;
  color: #2b2b2b;
}
.why_blue1 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(180deg, rgb(113, 146, 174) 0%, rgb(221,225,238,0) 100%);
  opacity: 50%;
  position: absolute;
  top: 26%;
  right: 8%;
  rotate: -45deg;
}
.why_blue2 {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: linear-gradient(180deg, rgb(91, 138, 178) 0%, rgb(221,225,238,0) 100%);
  opacity: 50%;
  position: absolute;
  top: -6%;
  left: 3%;
}
.why_blue3 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(180deg, rgb(101, 139, 172) 0%, rgb(221,225,238,0) 100%);
  opacity: 50%;
  position: absolute;
  bottom: -4%;
  left: 35%;
  rotate: 40deg;
}
.exclusive_mobdiv {
  display: none;
}
.check_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home_exclusive_txtdiv {
  width: 60%;
}


@media (min-width:1340px) {
  .home_herodiv {
    width: 100%;
    height: 100%;
  }
  .infield {
    height: 4.4rem;
  }
  .registerinfield{
    height: 4rem;
  }
  .home_herodiv1 {
    padding-top: 4%;
}
.whyek_card{
  height: 22rem;
}
.hero_swip {
  height: 21em;
}
.homeabt1 {
  height: 32rem;
}
.homeabt2 {
  height: 14rem;
}
.homeabt3 {
  height: 12rem;
}
.exe_img{
  height: 25rem;
}
}
@media (max-width: 1024px) {
  .home_herodiv {
    height: 43rem;
}
  .homehero_grid {
    grid-template-columns: 60% auto;
  }
  .hero_blue_circle {
    top: -10%;
  }
  .homeabt1 {
    height: 27rem;
  }
  .homeabt2 {
    height: 220px;
  }
  .homeabt3 {
    margin-top: 20px;
    height: 140px;
  }
  .exe_img {
    width: 100%;
    height: 260px;
  }
  .whyek_card {
    width: 100%;
    height: 280px;
  }
  .why_circle {
    width: 80px;
    height: 80px;
  }
  .why_blue1 {
    width: 80px;
    height: 80px;
    top: 25%;
  }
}

@media (max-width: 992px) {
  .hero_blue_circle {
    width: 50px;
    height: 50px;
    top: -8%;
    right: -5%;
  }
  .blue_circle {
    width: 70px;
    height: 70px;
    top: -15%;
    left: 10%;
  }
  .homehero_grid {
    grid-template-columns: 55% auto;
  }
  .form_headdiv {
    height: 50px;
  }
  .form_div {
    padding: 20px;
  }
  .infield {
    margin-bottom: 20px;
    border-radius: 10px;
    height: 40px;
  }
  .infield::placeholder {
    font-size: 12px;

  }
  .regbtn {
    height: 40px;
    font-size: 12px;
  }
  .hero_swip {
    height: 200px;
  }
  .home_hero_txtdiv {
    width: 80%;
  }
  .swip_content {
    width: 80%;
  }
  .abtimg1 {
    border-radius: 20px;
  }
  .homeabt1 {
    height: 20.5rem;
    border-radius: 20px;
  }
  .homeabt2 {
    height: 200px;
    border-radius: 20px;
  }
  .homeabt3 {
    margin-top: 20px;
    height: 100px;
    border-radius: 20px;
  }
  .cicle_text {
    height: 160px;
    width: 160px;
  }
  .blue_circle1 {
    width: 50px;
    height: 50px;
    left: 92%;
  }
  .why_blue2 {
    width: 60px;
    height: 60px;
  }
  .blue_circle2 {
    width: 50px;
    height: 50px;
  }
  .homeabt_data {
    border-radius: 20px;
    gap: 10px;
    padding: 10px 20px 5px 20px;
    margin-bottom: 20px;
  }
  .userdata_icon {
    width: 30px;
    height: 30px;
  }
  .exe_img {
    width: 100%;
    height: 12rem;
    border-radius: 20px;
  }
  .exe_rqst_div {
    height: 31px;
  }
  .whyek_card {
    width: 100%;
    height: 220px;
    padding: 20px;
    border-radius: 20px;
  }
  .why_circle {
    width: 60px;
    height: 60px;
  }
  .why_blue1 {
    width: 60px;
    height: 60px;
    top: 28%;
  }
  .homehero_maindivs {
    padding-top: 50px;
    height: auto;
  }
  .home_herodiv {
    padding-top: 3%;
    width: 100%;
    height: 34rem;
}
}

@media (max-width: 767px) {
  .home_exclusive_txtdiv {
    width: 100%;
  }
  .home_herodiv {
    padding-top: 5%;
    height: 50rem;
  }
  .homehero_grid {
    grid-template-columns: 100%;
    grid-gap: 30px;
  }

  .hero_swip {
    margin-top: 20px;
  }
  .form_heading {
    font-size: 4vw;
    line-height: 2;
  }
  .home_hero_txtdiv {
    width: 100%;
  }
  .swip_content {
    width: 100%;
  }
  .hero_blue_circle {
    width: 40px;
    height: 40px;
    top: -5%;
    right: 5%;
  }
  .about_maindiv {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
  }

  .homeabt_data {
    padding: 10px 30px 5px 30px;
    width: 100%;
    z-index: 1;
  }
  .home_deskdata {
    display: none;
  }
  .home_mobdata {
    margin-top: 8%;
    display: block;
  }
  .cicle_text {
    height: 124px;
    width: 124px;
    top: -6%;
  }
  .blue_circle1 {
  display: none;
  }
  .homeabt_mobdiv{
    position: relative;
  }
  .abt_blue_circle3{
    bottom: 40%;
    right: -4%;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: linear-gradient(180deg, #173A4B 0%, rgba(67, 97, 238, 0) 100%);
  }

  .blue_circle {
    width: 70px;
    height: 70px;
    top: -32%;
    left: -5%;
  }
  .homeabt1 {
    margin-top: 20%;
    height: 270px;
  }
  .homeabt2 {
    height: 150px;
  }
  .homeabt3 {
    margin-top: 20px;
  }
  .abtdata_head {
    font-size: 3.1vw;
    line-height: 1.6;
  }
  .exclusive_swiper_div {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    position: relative;
    z-index: 1;
  }
  .excl_bluediv{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: linear-gradient(180deg, #173A4B 0%, rgba(67, 97, 238, 0) 100%);
    position: absolute;
    bottom: 22%;
    left: -4%;
  }
  .exe_rqst_div {
    height: 41px;
    bottom: 8%;
  }
  .rqst_text {
    margin-top: 15px;
    font-size: 3.8vw;
  }
  .exe_name {
    font-size: 4vw;
    line-height: 1.2;
  }
  .exclusive_swipediv {
    display: none;
  }
  .exclusive_mobdiv {
    padding-top: 5%;
    padding-left: 8%;
    padding-right: 8%;
    display: block;
  }
  .why_maindiv {
    margin-top: 8%;
    padding-top: 20%;
    padding-bottom: 20%;
  }
  .whygrid_div {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
  .why_subheading_div{
    margin-bottom: 20%;
    padding: 0px 30px 0px 30px;
  }
  .whyek_card:hover {
    transform: none;
  }
  .why_blue1 {
    display: none;
  }
  .why_blue2 {
    display: none;
  }
  .why_blue3 {
    bottom: -2%;
    left: 70%;
  }
  .check_div {
    display: inline-block;
  }
  .buy_button {
    font-size: 3.5vw;
    line-height: 1.5;
  }
  .exe_img {
    width: 100%;
    height: 22rem;
    border-radius: 20px;
  }
  .exclusive_proprty_btn {
    margin-top: 3%;
    padding: 10px 20px 10px 20px;
    border: 1px solid #173a4b;
    background-color: #ffffff;
    border-radius: 30px;
    font-size: 3.8vw;
    font-weight: 500;
    line-height: 1.7;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #173a4b;
  }
  .exe_type {
    font-size: 4.5vw !important;
    line-height: 1;
  }
  .whyek_card {
    width: 100%;
    height: 20rem;
    border-radius: 20px;
  }
  .why_circle {
    width: 60px;
    height: 60px;
  }
  .handshake_icon {
    font-size: 20px;
  }
  .why_heading {
    font-size: 4vw;
    line-height: 2;
  }

}
@media (max-width: 320px) {
  .blue_circle1 {
    bottom: 6% !important;
    left: 90%;
  }

}
