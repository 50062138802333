.project_container_backdiv {
  padding: 3% 5%;
}
.shreeshyamherosec {
  background-image: url("../../../public/khatushyam.webp");
}
.aerocityherosec {
  background-image: url("../../../public/aerocity.webp");
}
.blissherosec {
  background-image: url("../../../public/innerblissvalley.webp");
}

.project_bluediv {
  padding: 5px 20px 0px 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.proj_name {
  margin-top: 12px;
  font-size: 2.6vw;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 2;
  text-align: left;
  color: #173a4b;
}
.property_name {
  font-size: 1vw;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.37692299485206604px;
  text-align: left;
  color: #ffffff;
  opacity: 60%;
}
.price-text {
  margin-top: 15px;
  font-size: 2.3vw;
  font-weight: 650;
  line-height: 1;
  letter-spacing: -0.80410236120224px;
  text-align: right;
  color: #173a4b;
}
.projectdiv_grid {
  margin-top: 3%;
  display: grid;
  grid-template-columns: 60% auto;
  grid-gap: 20px;
}
.innerproj_imgdiv {
  width: 100%;
  height: 480px;
  border-top-right-radius: 57px;
  overflow: hidden;
}
.township_img {
  width: 100%;
  height: 480px;
  object-fit: cover;
}
.project_formdiv {
  width: 100%;
  height: auto;
  box-shadow: 0px 32px 34px 0px #00000022;
  border-radius: 25px;
  padding: 30px;
}
.formfield {
  width: 100%;
  padding-left: 5%;
  height: 50px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  margin-bottom: 20px;
}
.formfield::placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  letter-spacing: -0.37692299485206604px;
  text-align: left;
  color: #000000;
}
.formarea::placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  letter-spacing: -0.37692299485206604px;
  text-align: left;
  color: #000000;
}
.formarea {
  width: 100%;
  height: auto;
  padding-top: 5%;
  padding-left: 5%;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  margin-bottom: 20px;
}
.project_contbtn {
  width: 100%;
  height: 60px;
  background-color: #000000;
  font-size: 17px;
  font-weight: 700;
  line-height: 25.5px;
  letter-spacing: -0.42717939615249634px;
  text-align: center;
  color: #ffffff;
  border-top-right-radius: 18px;
}
.project_contentdiv {
  margin-top: 5%;
}
.project_heading {
  padding-left: 3%;
  font-size: 1.5vw;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.502564013004303px;
  text-align: left;
  color: #000000;
}
.feature_div {
  margin-top: 3%;
}
.hr {
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
  margin-top: 3%;
  margin-bottom: 3%;
}
.listgrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border: 1px solid #eee;
}
.listingicon{
    color: rgb(171,198,212);
    font-size: 3vw;
    text-align: center;
}
.listinghead{
    font-size: 1vw;
    color: #666;
    text-align: center;
}
.listingtext{
    font-size: 1.2vw;
    font-weight: 700;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    margin-top: 5px;
}
.check1_div {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #173a4b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature_list {
  display: flex;
  gap: 20px;
}
.check_icon {
  font-size: 20px;
  color: #ffffff;
}
.feature_list_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.list_div {
  width: 100%;
}
.project_layout {
  position: sticky;
  margin-top: 15%;
  width: 100%;
  height: 400px;
  top: 80px;
}
.proj_layoutimg {
  width: 100%;
  height: 30rem;
}
.innerbackground-video {
  width: 94%;
  height: 28rem;
  margin-left: 3%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 30px;
  margin-top: 80px;
}

.innerhero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.innerbackground-video::before {
  content: "";
  /* position: absolute; */
  width: 100%;
  height: 100%;
  background-color: #173a4b57 !important;
  border-radius: 30px;
}
@media (min-width: 1340px) {
  .innerbackground-video {
    height: 30rem;
  }
}
@media (max-width: 992px) {
  .projectdiv_grid {
    grid-template-columns: 55% auto;
  }
  .innerproj_imgdiv {
    height: 400px;
  }
  .project_formdiv {
    border-radius: 20px;
    padding: 20px;
  }
  .formfield {
    height: 40px;
    margin-bottom: 15px;
  }
  .formarea {
    margin-bottom: 15px;
  }
  .project_contbtn {
    height: 50px;
    font-size: 2vw;
  }
  .innerbackground-video {
    height: 17rem;
  }
}
@media (max-width: 767px) {
  .project_container_backdiv {
    padding-left: 8%;
    padding-right: 8%;
  }
  .projectdiv_grid {
    grid-gap: 0px;
    grid-template-columns: 100%;
  }
  .project_formdiv {
    display: none;
  }
  .price-text {
    font-size: 4vw;
  }
  .project_bluediv {
    padding: 5px 10px 0px 10px;
  }
  .proj_name {
    font-size: 3.5vw;
  }
  .innerproj_imgdiv {
    height: 300px;
  }
  .project_heading {
    font-size: 4vw;
  }
  .property_name {
    font-size: 3vw;
  }
  .feature_list_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  .project_layout {
    margin-bottom: 30%;
    margin-top: 0%;
    width: 100%;
    height: 400px;
  }
  .listgrid {
    grid-template-columns: repeat(1, 1fr);
}
.listinghead {
  font-size: 2.5vw;
}
.listingtext {
  font-size: 4.2vw; 
}
.listingicon {
  font-size: 7vw;
}
.township_img {
  height: 18rem;
}
.innerbackground-video {
  height: 14rem;
}
}